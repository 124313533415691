<template>
<div id="app" class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article id="post-3" class="post-3 page type-page status-publish">
            <div class="inside-article">
              <header class="entry-header" aria-label="Content">
                <h1 class="entry-title" itemprop="headline">
                  Disclaimer
                </h1>
              </header>

              <div class="entry-content" itemprop="text">
                <p>
                  The information provided on <a href="https://www.gbwhatsapp.chat/" class="jump-url">www.gbwhatsapp.chat</a> is for educational and informational purposes only. We do not promote, host, or distribute any modified or unofficial applications. Any downloads or modifications are done at the user's own risk.
                </p>
                <ul>
                  <li>We are not affiliated with WhatsApp Inc.</li>
                  <li>Users should comply with their local laws and regulations.</li>
                  <li>We do not take responsibility for any issues arising from the use of third-party applications.</li>
                </ul>
                <p>
                  For any concerns, please contact us.
                </p>
              </div>

              <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://www.gbwhatsapp.chat/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Privacy',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'privacy',
    };
  },
  mounted() {
    document.documentElement.lang = 'en';
  },
};
</script>
